<template>

  <div class="cla-conter">
    <!-- <div v-if="!useType" class="all-box">
        <div  class="item-box" v-for="(item,index) in list" :key="index" >
            <div class="one-box"  @click="chooseItem(item)">
                <div class="xianshiyouhui" v-if="item.hasOriginal"> <img src="@/assets/setimg/minghuo.png"/><span>限时优惠</span></div>
                <img :src="item.goods_imgs"/>
                <div class="word">{{item.name}}</div>
                <div class="synopsis">{{item.describe}}</div>
                <Button size='large' class="but-class">了解详情</Button>
            </div>
        </div>
    </div>   -->

    <div v-if="!useType" >
        <div v-for="(meauitem,index) in meauClassList" :key="index" >
            <div class="new-box" v-if='haveClass.indexOf(meauitem.id)!==-1'>
                <div class="new-head" v-if='meauitem.id == 1'><div class="head-tittle">{{meauitem.name}}</div><div class="head-shadow">contract</div></div>
                <div class="new-head" v-if='meauitem.id == 2'><div class="head-tittle">{{meauitem.name}}</div><div class="head-shadow">assist</div></div>
                <div class="new-head" v-if='meauitem.id == 3'><div class="head-tittle">{{meauitem.name}}</div><div class="head-shadow">mobile</div></div>
                <div class="new-head" v-if='meauitem.id == 4'><div class="head-tittle">{{meauitem.name}}</div><div class="head-shadow">message</div></div>
                <div class="new-cont">
                    <div v-for="(item,index) in list" :key="index">
                        <div class="new-cont-item" v-if="item.class_id == meauitem.id" @click="chooseItem(item)">                        
                            <div class="cont-one-item"  >
                                <div class="cont-title">
                                    <img  v-if='meauitem.id == 1||meauitem.id == 4' style="width: 20px;height: 20px;margin-top: 5px;" src="@/assets/setimg/lan-shuye.png"/>
                                    <img  v-if='meauitem.id == 2' style="width: 20px;height: 20px;margin-top: 5px;" src="@/assets/setimg/hong-shuye.png"/>
                                    <img  v-if='meauitem.id == 3' style="width: 20px;height: 20px;margin-top: 5px;" src="@/assets/setimg/zi-shuye.png"/>
                                    <div class="tit-name">{{item.name}}</div>
                                    <img v-if="item.hasOriginal"  style="width:14px;height: 20px;margin-top: 5px;margin-left:8px" src="@/assets/setimg/big-minghuo.png"/>                            
                                </div>
                                <div class="cont-synopsis">{{item.describe}}</div>
                                <div class="cont-bottom">
                                    <img  v-if='meauitem.id == 1||meauitem.id == 4' src="@/assets/setimg/but-bottom.png"/>  
                                    <img  v-if='meauitem.id == 2'  src="@/assets/setimg/red-bottom.png"/>
                                    <img  v-if='meauitem.id == 3'  src="@/assets/setimg/zi-bottom.png"/>  
                                </div>
                                <div v-if='meauitem.id == 1||meauitem.id == 4' class="cont-but"><div class="but-blue"> 了解详情</div></div>
                                <div v-if='meauitem.id == 2' class="cont-but-red"><div class="but-blue"> 了解详情</div></div>
                                <div v-if='meauitem.id == 3' class="cont-but-zi"><div class="but-blue"> 了解详情</div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="new-box">
            <div class="new-head">
                 <div class="head-tittle">合同管理</div>
                 <div class="head-shadow">contract</div>
                 <img style="width: 200px;height: 50px"  src="@/assets/setimg/aboutContract.png"/>
            </div>
            <div class="new-cont">
                <div class="new-cont-item" v-for="(item,index) in list" :key="index" >
                    <div class="cont-one-item">
                        <div class="cont-title">
                            <img  style="width: 20px;height: 20px;margin-top: 5px;" src="@/assets/setimg/lan-shuye.png"/>
                            <div class="tit-name">{{item.name}}</div>
                            <img v-if="item.hasOriginal"  style="width:14px;height: 20px;margin-top: 5px;margin-left:8px" src="@/assets/setimg/big-minghuo.png"/>                            
                        </div>
                        <div class="cont-synopsis">{{item.describe}}</div>
                        <div class="cont-bottom">
                            <img  style="" src="@/assets/setimg/but-bottom.png"/>    
                        </div>
                        <div class="cont-but">
                            <div class="but-blue"> 了解详情</div>            
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- <div class="new-box">
            <div class="new-head">
                <img style="width: 168px;height: 50px"  src="@/assets/setimg/aboutMessage.png"/>     
            </div>
            <div class="new-cont">
                <div class="new-cont-item" v-for="(item,index) in list" :key="index" >
                    <div class="cont-one-item">
                        <div class="cont-title">
                            <img  style="width: 20px;height: 20px;margin-top: 5px;" src="@/assets/setimg/hong-shuye.png"/>
                            <div class="tit-name">{{item.name}}</div>
                            <img v-if="item.hasOriginal"  style="width:14px;height: 20px;margin-top: 5px;margin-left:8px" src="@/assets/setimg/big-minghuo.png"/>                            
                        </div>
                        <div class="cont-synopsis">{{item.describe}}阿松大阿松大阿松大阿松大阿松大阿松大阿松大阿松大阿松大阿松大阿松大阿松大阿松大阿松大阿松大阿松大阿松大</div>
                        <div class="cont-bottom">
                            <img  style="" src="@/assets/setimg/red-bottom.png"/>    
                        </div>
                        <div class="cont-but-red">
                            <div class="but-red"> 了解详情</div>            
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- <div class="new-box">
            <div class="new-head">
                <img style="width: 279px;height: 50px"  src="@/assets/setimg/aboutGxh.png"/>      
            </div>
            <div class="new-cont">
                <div class="new-cont-item" v-for="(item,index) in list" :key="index" >
                    <div class="cont-one-item">
                        <div class="cont-title">
                            <img  style="width: 20px;height: 20px;margin-top: 5px;" src="@/assets/setimg/zi-shuye.png"/>
                            <div class="tit-name">{{item.name}}</div>
                            <img v-if="item.hasOriginal"  style="width:14px;height: 20px;margin-top: 5px;margin-left:8px" src="@/assets/setimg/big-minghuo.png"/>                            
                        </div>
                        <div class="cont-synopsis">{{item.describe}}阿松大阿松大阿松大阿松大阿松大阿松大阿松大阿松大阿松大阿松大阿松大阿松大阿松大阿松大阿松大阿松大阿松大</div>
                        <div class="cont-bottom">
                            <img  style="" src="@/assets/setimg/zi-bottom.png"/>    
                        </div>
                        <div class="cont-but-zi">
                            <div class="but-red"> 了解详情</div>            
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </div> 

    <Purchase v-if="useType === '1000'" @goBack="afresh"></Purchase>    
    <MessageTemplate v-if="useType === '999'" @goBack="afresh"></MessageTemplate>
    <MessageTemplate v-if="useType === '998'" @goBack="afresh"></MessageTemplate>
    <IndependentDomain v-if="useType === '997'" @goBack="afresh"></IndependentDomain>
    
  </div>
</template>

<script>
import Purchase from './centerComponents/purchase'
import MessageTemplate from './centerComponents/messageTemplate'
import IndependentDomain from './centerComponents/independentDomain'
import { get_appcenterlist,get_menuclass } from '@/api/center.js'

export default {
    name: "center",
    components:{
        Purchase,
        MessageTemplate,
        IndependentDomain,
    },
    data(){
        return{
            list: [],   
            meauClassList:[], 
            haveClass: [], 
        }
    },
    computed: {
        settingList(){
            return this.$store.state.centerMenu
        },
        useType(){
            return this.$store.state.activeType
            // return 'sms-signature'
        }
    },
    watch:{        
    },
    created() {    
        this.$store.commit('changePollingSpin', true)
        this.get_menuclass()    
        this.get_appcenterlist() 
    },
    mounted(){       
    },
    methods:{    
        get_menuclass(){
            get_menuclass().then(res=>{
                console.log('权限分类',res) 
                this.meauClassList = res.data.reverse()              
            }).catch(res=>{
                this.$Message.error(res.msg)
            })
        } ,
        get_appcenterlist(){
            get_appcenterlist().then(res=>{
                console.log('获取列表',res)
                this.list = res.data.menuAll
                this.list.forEach(item => {
                    this.haveClass.push(item.class_id)
                    let a  = false
                    item.price_group.forEach(it => {
                        if(it.original_price != 0){
                            a = true
                        }
                    })
                    if(a){
                        item.hasOriginal = true
                    }
                });  
            }).catch(res=>{
                this.$Message.error(res.msg)
            })
        } ,
        afresh(){
            this.get_appcenterlist()
            this.$store.commit('setType', '')
        },
        chooseItem(e){             
            console.log(e)
            let a  = JSON.parse(JSON.stringify(e))
            if(a.permissions_id == 1000){
                this.$store.commit('setType','1000')
            }else if(a.permissions_id == 999){
                this.$store.commit('setType','999')
            }else if(a.permissions_id == 998 ){
                this.$store.commit('setType','998')
            }else if(a.permissions_id == 997 ){
                this.$store.commit('setType','997')
            } else {
                this.$store.commit('setType','1000')
            }
            localStorage.setItem("centerType",JSON.stringify(e))
        },
        // goduanxin(e){
        //     this.$store.commit('setType',e)
        // }
    }


}
</script>

<style scoped lang="scss">
.cla-conter{
    width: 100%;
}
.new-box {
    width:99%;
    background: #FFFFFF;
    padding: 8px;
    margin-bottom: 24px;
    .new-head {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-top: 24px;
        .head-tittle {
            position:absolute;
            top:-10px;
            left:48%;
            font-size: 28px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #2d3344;
            left: 50%;  
            top:50%;     
            transform:translate(-50%,-50%);
            user-select:none;
        }
        .head-shadow{
            margin-top: 12px;
            font-size: 39px;
            position: absolute;
            opacity:0.1;
            left: 50%;  
            top:50%;     
            transform:translate(-50%,-50%);
            text-decoration:underline;
            user-select:none;
        }
    }
    .new-cont {
        width:99%;
        margin-top: 68px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-left: 1%;
        .new-cont-item {
            width: 316px;
            margin-bottom: 32px;
            justify-content: center;            
            .cont-one-item {
                &:hover {
                    border: 1px solid #4877E8;
                }
                box-shadow: rgb(204, 204, 204) 0 0 5px 1px;
                width: 90%;
                margin-left: 10% ;
                margin-right: 10% ;
                height: 208px;
                background: #FFFFFF;        
                cursor: pointer;
                padding: 20px;
                border-radius: 3px;
                border: 2px solid #fff;
                position: relative;
                .cont-title {
                    width: 100%;
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    .tit-name {
                        margin-left: 8px;
                        color: #545F78;
                        font-size: 20px;
                        font-weight: bold;
                        font-family: PingFang SC;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical; 
                    }
                }
                .cont-synopsis {
                    width: 100%;
                    margin-top: 18px;
                    letter-spacing:1px;
                    overflow:hidden;
                    display: -webkit-box;
                    font-size: 15px;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical; 
                }
                .cont-bottom {
                    position: absolute;
                    bottom: 0;                   
                    img {
                        width: 232px;
                        height: 75px; 
                    }
                }
                .cont-but {
                    position: absolute;
                    bottom: 0;
                    cursor: pointer;
                    width: 232px;
                    height: 38px;
                    line-height: 38px;
                    background: linear-gradient(to right, #40C6FF 100%, #39AEFF 100%) !important;
                    color: #fff;
                    margin-bottom: 12px ;
                    text-align: center;
                    font-size: 16px;
                }
                .cont-but:hover{
                    opacity: 0.6;
                }
                .cont-but-red {
                    position: absolute;
                    bottom: 0;
                    cursor: pointer;
                    width: 232px;
                    height: 38px;
                    line-height: 38px;
                    background: linear-gradient(to right, #FF8292 100%, #FB6966 100%) !important;
                    color: #fff;
                    margin-bottom: 12px ;
                    text-align: center;
                    font-size: 16px;
                }
                .cont-but-red:hover{
                    opacity: 0.6;
                }
                .cont-but-zi {
                    position: absolute;
                    bottom: 0;
                    cursor: pointer;
                    width: 232px;
                    height: 38px;
                    line-height: 38px;
                    background: linear-gradient(to right, #9E97E5 100%, #736BE2 100%) !important;
                    color: #fff;
                    margin-bottom: 12px ;
                    text-align: center;
                    font-size: 16px;
                }
                .cont-but-zi:hover{
                    opacity: 0.6;
                }
            }

        }
    }
}

.all-box{
    width:99%;
    display: flex;
	flex-wrap: wrap;
    align-items: center;
    margin-left: 1%;
    .item-box{
        // width: 20%;
        width: 300px;
        // height: 400px;
        margin-bottom: 30px;
        justify-content: center;
        .one-box{
            box-shadow:5px 5px 10px 5px #ccc;
            width: 90%;
            height: 300px;
            background: #FFFFFF;
            border-radius: 12px;
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;
            border: 2px solid #fff;
            box-sizing: border-box;
            position: relative;
            &:hover {
                border: 2px solid #4877E8;
            }
            .iconfont {
                font-size: 80px;
                color: #6D92ED;
            }
            .word {
                font-size: 28px;
                color: #545F78;
                padding-left: 15px;
                padding-right: 15px;
            }
            img {   
                margin-top:30px ;
                margin-bottom:15px ;
                width: 60px;
                height: 60px;
            }
            .synopsis {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical; 
                // height: 110px;
                // display: flex;
                padding: 0 10px ;
                margin-top: 12px;
                font-size: 15px;
                color: #545F78 ;
                overflow:hidden;
                // overflow: scroll;
                // overflow-x:hidden
            }
            .synopsis::-webkit-scrollbar{
                display: none;
            }
            .xianshiyouhui {
                position: absolute;
                top: 5px;
                left: 5px;
                height: 20px;
                display: flex;
                justify-content: flex-end;
                align-items: flex-start;
                // background: #FF8A00 ;
                background-color:		rgba(255,138,0,0.2);
                border-radius: 6px;
                padding: 0px 5px;
                padding-right: 10px;
                img {
                    margin-top: 5px ;
                    width: 10px;
                    height: 10px;
                    margin-left: 5px;
                    margin-right:  5px;
                }
                span {
                    color: #FF8A00;
                }
            }
            .but-class {
                position: absolute;
                bottom:14px;
                color: #4877E8;
                // border: 1px solid #4877E8;
            }
        }
    }
}
</style>