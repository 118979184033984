<template>
  <div class="cont-cla">
    <div class="top-change" @click="goBack">
        <div class="l-where">系统设置<span>/</span>{{ centerDate.name }}</div>
        <div class="r-back" @click="goBack">
            <i class="iconfont icon-fanhui"></i>
        </div>
    </div>
    <div class="search-list">
        <div class="list-al">关键词：<Input placeholder="请输入关键词" v-model="searchDate.title"/></div>
        <div class="list-al">状态：
            <Select v-model="searchDate.status" @on-change="get_list" style="width: 200px"  placeholder="请选择订单状态"  >
                <Option v-for="item in typeList"  :value="item.id" :key="item.id">{{ item.label }}</Option>
            </Select>
        </div>
        <div class="list-al">日期：
            <div class="r-find">
              <DatePicker @on-change="changeTime" v-model="timeVal" type="daterange" placement="bottom-end" placeholder="请选择截至日期" style="width: 240px"></DatePicker>
            </div>
        </div>
        <div class="list-al">
            <Button type="primary"    @click="searchList">查询</Button>
        </div>
    </div>
    <div class="table-body">
        <div class="table-wrap">      
           <div class="table-btn blue" @click="creatTem">创建短信{{centerDate.permissions_id == '998'?'模板':'签名'}}</div>    
           
           <div style="margin-left:12px">短信{{centerDate.permissions_id == '998'?'模板':'签名'}}:</div> <div class="table-mon">￥{{centerDate.price_group[0].price}}/1次</div>           
             <div >，如有疑问，可联系客服。点击</div> <div class="see-xq" @click=" showJS = true">查看详情</div>
        </div>
        <Table :columns="columnTable"  :data="dataTable" :loading='loadingTab'>
            <template slot-scope="{ row }" slot="status" >
                <span v-if="row.status=='0'" style="color: #4877E8 ">未付款</span>
                <span v-if="row.status=='1'" style="color: #545F78 ;margin-left: 10px">审核中..</span>                
                <span v-if="row.status=='3'" style="color: #2A7E1C ;position: relative;">已通过<img class="img-cla" src="@/assets/setimg/gougou.png"/></span>
                <span v-if="row.status=='2'" style="color: #FD5454 ;position: relative;">未通过<img class="img-cla" src="@/assets/setimg/chacha.png"/></span>                
                <span v-if="row.status=='4'" style="color: #FFB519 ">使用中</span>                
            </template>
            <template slot-scope="{row}" slot="action">
                <div class="flex-s" >
                    <div class="table-btn blue" v-if="row.status=='2'||row.status=='0'" @click="editRow(row)">编辑</div>
                    <div class="table-btn search" v-if="row.status=='1'||row.status=='3'||row.status=='4'" @click="kaiqi(row)">查看</div>
                    <div class="table-btn yellow" v-if="row.status=='3'" @click="gotouse(row.id)">使用</div>
                    <div class="table-btn danger" v-if="row.status=='2'" @click="subTemplete(row)">提交</div>
                    <div class="table-btn zhifu" v-if="row.status=='0'" @click="tab_pay(row)">支付</div>
                </div>
            </template>
        </Table>
        <Page 
            :total="total"
            :current="searchDate.page"
            style="text-align:right;margin-top: 20px"
            show-elevator
            show-total
            @on-change="pageChange"
            :page-size="searchDate.limit"
        />
    </div>
    <Modal  :title="formTitle=='add' ? 
                '新增短信'+ (centerDate.permissions_id == '998'?'模板':'签名') : 
                formTitle=='edit'? 
                    '编辑短信' + (centerDate.permissions_id == '998'?'模板':'签名') : 
                    '查看短信' + (centerDate.permissions_id == '998'?'模板':'签名') " 
            v-model="showMessage" :mask-closable="false" footer-hide width="400px" >
        <div style="padding:0px 16px">
            <div style="font-weight: 800;margin-bottom: 10px;">{{centerDate.permissions_id == '998'?'模板':'签名'}}内容</div>
            <div style="padding: 5px 0"> <Input :disabled="formTitle =='polling'" v-model="value6" type="textarea" :rows="4" :placeholder="'请输入短信'+(centerDate.permissions_id == '998'?'模板':'签名')"></Input></div>
        </div>
        <div class="moadl-foot">
            <Button v-if=" formTitle=='add' || formTitle=='edit'"  @click="onShowMessage">取消</Button>
            <Button type="primary" v-if="formTitle == 'add'"  @click="goPayment">支付</Button>
            <Button type="primary" v-if="formTitle == 'edit'" :loading='loadinga'  @click="editTrue">确定</Button>
            <Button type="primary" v-if="formTitle == 'polling'"  @click="showMessage = false">确定</Button>    
        </div>        
    </Modal>
    <Modal  :title="'短信' + (centerDate.permissions_id == '998'?'模板详情':'签名详情') " 
            v-model="showJS" :mask-closable="false" footer-hide width="600px" >
            <div class="bottom-swip">
                <div v-if='centerDate.details_information' v-html="centerDate.details_information"></div>
                <div v-else style="text-align: center;">暂无详情~</div>
            </div>     
            <div class="moadl-footx">         
                <Button type="primary" @click="showJS = false">确定</Button>
            </div>  
            
    </Modal>

    <Modal v-model="showPay" :mask-closable="false" footer-hide width="600px"  class="canshowcont">
        <div class="mod-pay-cont">
            <div class="cha-cha" @click="closeMod"><img src="@/assets/setimg/guanbi.png" /></div>
            <div class="pay-title" ><span >{{ centerDate.name }}</span></div> 
            <div class="pay-num"><span class="line"> ￥{{ formDate.price }}</span></div>
            <div class="pay-pay">
                <div class="pay-items"> <img @click="goPayMoney(1)" src="@/assets/setimg/weixin.png" /><div style="margin:0 5px"><Radio @on-change="payWechat" v-model="wechat">微信支付</Radio></div> </div>
                <div class="pay-items"> <img @click="goPayMoney(2)"  src="@/assets/setimg/zhifubao.png" /><div style="margin:0 5px"><Radio disabled  @on-change="payMoth" v-model="zfbchat">支付宝支付</Radio></div> </div>
            </div>
        </div>
    </Modal>
    <Modal v-model="payMa" :mask-closable="false" footer-hide width="600px" class="canshowcont">
        <div class="mod-pay-cont">
            <div class="cha-cha" @click="closePay"><img src="@/assets/setimg/guanbi.png" /></div>
            <div class="pay-title1" v-if="zfbchat"> <span>请使用支付宝扫码付款</span></div> 
            <div class="pay-title1" v-if="wechat"> <span>请使用微信扫码付款</span></div>           
            <div class="pay-num"><span class="line"> ￥{{ order_price }}</span></div>
            <div class="pay-ma">             
                <Spin fix v-if="PayEWM==''">
                    <img class="demo-spin-icon-load" style="width:50px;height:50px"  src="@/assets/setimg/20220720113958.gif" />
                    <div>Loading...</div>
                </Spin>
                <img v-if="PayEWM !=''" :src="PayEWM" />
            </div>
        </div>
    </Modal>
    <Modal v-model="paySucces" :mask-closable="false" footer-hide width="600px" class="canshowcont">
        <div class="mod-pay-cont">
            <div class="cha-cha" @click="closeSuc"><img src="@/assets/setimg/guanbi.png" /></div>
        </div>
        <div class="mod-pay-succ">
            <div><img src="@/assets/setimg/zhifuwancheng.png" /></div>
            <span class="titlesuc">恭喜你支付完成!</span>
            <div class="over-list" style="margin-top: 30px">商品订单：<span>{{payFormData.order_no}}</span></div>
            <div class="over-list" >商品名称：<span>{{centerDate.name}}</span></div>
            <div class="over-list" style="margin-bottom:40px">
            支付金额：
            <span>¥{{payFormData.pay_money}}</span>
            <span v-if="payFormData.number  != 0">/{{payFormData.number}}条</span>
            </div>
        </div>
    </Modal>
  </div>
</template>

<script>
import { get_list,update_signtemplate,pay_order,get_orderstatus,update_status,get_menulist } from '@/api/center.js'
export default {
    data(){
        return{
            PayEWM: '', 
            order_price: '',
            wechat: '',
            zfbchat: '',
            timer: '',
            value6: '',
            order_id: '',
            editid: '',
            formTitle: 'add', 
            paySucces: false,       
            showPay: false,     
            payMa: false,
            showJS: false,
            showMessage: false,
            loadinga: false,
            loading: false,  
            total: 0,
            timeVal: [],
            loadingTab: false,
            dataTable: [],            
            payFormData: {
                order_no: "",
                pay_money: "",
                number: "",
            },                       
            searchDate: {
                start_time: '',
                end_time: "",
                status: 5 ,
                page: 1,
                type: "",
                title: '',
                limit: 10,           
            },            
            typeList: [
                {id: 5 , label: '全部状态'},
                {id: 0 , label: '待付款'},
                {id: 1 , label: '审核中'},
                {id: 2 , label: '未通过'},
                {id: 3 , label: '已通过'},
                {id: 4 , label: '使用中'},                
            ],  
            formDate: {
                describe: "",
                id: "",
                number: 0,
                price: "",
                type: "",
            },
            columnTable: [
                {
                    title: '序号',
                    key: 'id',
                    align: 'center',
                    width: 80
                },
                {
                    title: '文本说明',
                    key: 'title',
                    align: 'center'
                },
                {
                    title: '模板状态',
                    key: 'status',
                    align: 'center',
                    slot: 'status',
                },
                {
                    title: '未通过原因',
                    key: 'failure_reason',
                    align: 'center',
                },
                {
                    title: '申请时间',
                    key: 'created_at',
                    align: 'center'
                },               
                {
                    title: '操作',
                    slot: 'action',
                    width: 320,
                    align: 'center'
                }
            ],
            centerDate:{
                icon: "",
                id: "",
                is_menu: "",
                is_rightmenu: "",
                is_submenu: "",
                path: "",
                permissions_name: "",
                permissions_rote: "",
                permissions_id: "",
                route_name: "",
                pid: "",
                type: "",
                plug_id: "",
                hasOriginal: "",
            },
            
        }
    },
    created(){
        // 判断客户是否看到过短信模板/签名详情
        this.$store.commit('changePollingSpin', false)
        if(!localStorage.getItem("isShowJS")){
            this.showJS = true
            localStorage.setItem("isShowJS",'1')
        }else {
            localStorage.setItem("isShowJS",'1')
        }        
        this.centerDate = JSON.parse(localStorage.getItem("centerType"))   //获取应用详情
        console.log('应用详情',this.centerDate)
        if(this.centerDate.permissions_id == '998'){
            this.searchDate.type = 2
        }else{
            this.searchDate.type = 1
        }
        this.get_list()
    },
    methods: {
        searchList(){
            this.searchDate.page = 1
            this.get_list()
        },
        get_list(){
            this.loading = true     
            this.loadingTab = true       
            get_list(this.searchDate).then(res=>{
                this.dataTable = res.data.data
                this.total = res.data.total     
                this.loading = false
                this.loadingTab = false           
            }).catch(res=>{
                this.$Message.error(res.msg)
                this.loadingTab = false
                this.loading = false   
            })
        },
        payMoth(val){
            console.log(val)
        },
        payWechat(val){
            console.log(val)
        },
        changeTime(e){
            this.searchDate.start_time = e[0]
            this.searchDate.end_time = e[1]
        },
        pay_order(e){            
            pay_order({ id:e , pay_type: 1 }).then(res=>{
                this.PayEWM = res.data.qrcode_url
                this.order_price = res.data.price
                this.pollingOrder(res.data.out_trade_no)
            }).catch(res=>{
                this.payMa = false
                this.$Message.error(res.msg)
                clearInterval(this.timer)
            })
        },
        goBack() {
            this.$emit("goBack");
        },   
        get_menulist(){
            get_menulist().then(res=>{
                console.log('获取左边菜单列表',res)
                this.$store.commit('setAuth', res.data.unique_auth)  
                this.$store.commit('setMenus', res.data.menuAll)
            }).catch(res=>{
                this.$Message.error(res.msg)
            })
        },
        // this.$store.commit('setAuth', data.unique_auth)     
        closeSuc(){
            this.paySucces = false
            this.get_list()
        },
        pageChange(index) {
            this.searchDate.page = index
            this.get_list()
        },
        subTemplete(e) {
            this.$Modal.confirm({
            title: '提示',
            content: `是否确认再次审核此条短信`+ (this.centerDate.permissions_id == '998'?`模板`:`签名`),
            onOk: () => {
                update_signtemplate({id:e.id,status:2,title:e.title,application_id: this.centerDate.plug_id}).then(res=>{
                    this.$Message.success(res.msg)
                    this.get_list()
                }).catch(err=>{
                    this.$Message.error(err.msg)
                    this.get_list()
                })
            },
            onCancel: () => {
            }
            });
        },
        onShowMessage(){
            this.showMessage = false
            this.value6 = ''
        },
        editTrue (){
            if( this.value6 == '' ) return this.$Message.error("请输入文本说明")
            this.update_signtemplate(this.editid)
        },
        tab_pay(e){
            this.payMa = true
            this.pay_order(e.order_id)
        },
        goPayment(){
            if( this.value6 == '' ) return this.$Message.error("请输入文本说明")
            
            this.showMessage = false            
            this.get_list()
            let x = ''
            this.update_signtemplate(x)
        },
        update_signtemplate(e){
            let data = {
                title: this.value6,
                type: this.centerDate.permissions_id == '998'? 2 : 1,
                id: e,
                application_id: this.centerDate.plug_id
            }
            this.loadinga = true
            update_signtemplate(data).then(res=>{
                if(res.code == 200){
                    this.payMa = true
                    this.loadinga = false
                    this.order_id = res.data.order_id              
                    this.showMessage = false
                    if( e == ''){
                        this.pay_order(res.data.order_id)                    
                    }else{
                        this.loadinga = false
                        this.$Message.success(res.msg)
                        this.get_list()
                    }
                }else{
                    this.loadinga = false
                    this.$Message.success(res.msg)
                }
                
            }).catch(res=>{
                this.$Message.error(res.msg)
            })
        },
        closePay(){
            this.payMa = false
            this.get_list()
            this.PayEWM = ''
            this.order_price = ''
            clearInterval(this.timer)
        },
        kaiqi(e){
            this.formTitle = 'polling'
            this.showMessage = true
            this.value6 = e.title
        },
        editRow(e){
            this.editid = ''
            this.formTitle = 'edit'
            this.showMessage = true
            this.value6 = e.title
            this.editid = e.id
        },
        gotouse(e){
            this.$Modal.confirm({
            title: '提示',
            content: `是否确认切换此条短信`+ (this.centerDate.permissions_id == '998'?`模板使用`:`签名使用`),
            onOk: () => {
                update_status({id:e}).then(res=>{
                    this.$Message.success(res.msg)
                    this.get_list()
                }).catch(err=>{
                    this.$Message.error(err.msg)
                })
            },
            onCancel: () => {
            }
            });
        },
        closeMod(){
            this.showPay = false
            clearInterval(this.timer)
        },
        creatTem(){
            this.formTitle = 'add'
            this.value6 = ''
            this.showMessage = true
        },
        pollingOrder(e){
            this.timer = setInterval(() => {
                this.$store.commit('changePollingSpin', false)
                get_orderstatus({ order_no:e}).then(res=>{
                    if(res.data.is_pay == 1){
                        this.payOK(res)
                        clearInterval(this.timer)
                    }
                    this.$store.commit('changePollingSpin', true)
                }).catch(res=>{
                    this.$store.commit('changePollingSpin', true)
                    this.$Message.error(res.msg)
                    clearInterval(this.timer)
                })
            }, 2000)
        },
        payOK(a){
            if(a.code == 200){
                if(this.payMa){
                    this.paySucces = true
                    this.payMa = false
                    this.showPay = false
                    this.payFormData.pay_money = a.data.pay_money
                    this.payFormData.order_no = a.data.order_no
                    this.payFormData.number = a.data.number                     
                }
                this.get_menulist()
            }else{
                console.log('出错了')
            }
        }, 
        goPayMoney(e){
            if(e == 1){
                this.PayEWM = ""
                this.order_price = ''
                this.zfbchat = false
                this.wechat = true
                this.payMa = true
                //   let data={
                //     type:0,
                //     price_id: this.formDate.id,
                //     uniacid_id: this.userInfo.uniacid_id,
                //     application_id: this.centerDate.plug_id,
                //     payment_type: 1   //1微信  2支付宝
                //   }
                //   cs_chargepay(data).then(res=>{
                //     console.log('res',res)            
                //     if(res.code == 400 && this.centerDate.permissions_id != 1000){
                //       this.payMa = false
                //       return  this.$Message.error(res.msg)
                //     }else {
                //       this.PayEWM = res.data.qrcode_url
                //       this.pollingOrder(res.data.out_trade_no)
                //     }
                //   }).catch(res=>{
                //     console.log(res)
                //   })
            }else{
                // this.wechat = false
                // this.zfbchat = true
                // this.payMa = true
            }
        },
    }
    

}
</script>

<style scoped lang="scss">
::v-deep .ivu-modal-header-inner{
    font-weight: 500 !important;
}
::v-deep .ivu-modal-body{
    padding: 16px 0px !important;
}

.table-body {
    width: 100%;
    height: 100%;
    background: #ffffff;
    margin: 20px  0px;
    padding: 10px 30px;
}
.cont-cla {
    width: 100%;
    height: 100%;
}
.img-cla {
    position: absolute;
    top: 1px;
    left: 50px;
    width: 15px;
    height: 15px;
}
.top-change {
    width: 100%;
    cursor: pointer;
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 30px;
    box-sizing: border-box;
    height: 60px;
    .l-where {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #1b2742;
        span {
        color: #4877e8;
        }
    }
    .r-back {
        .iconfont {
            font-size: 26px;
            cursor: pointer;
            &:hover {
                color: #4877e8;
            }
        }
    }
}
.see-xq {
    color: #4877e8;
    margin-left: 3px;
}
.cha-cha {
    cursor: pointer;
    position: absolute;
    overflow: hidden;
    color: #fff;
    z-index: 1;
    right: -50px;
    top: -20px;
}
.search-list {
    width: 100%;
    padding:10px 30px;
    padding-bottom: 20px;
    color: #545F78;
    background: #ffffff;
    font-family: PingFang SC;
    border-radius: 8px;
    margin-top: 18px ;
    display: flex;
    line-height:32px;
    word-break:keep-all;
    white-space:nowrap; 
    flex-wrap: wrap;
}
.but-bar {
    border-radius: 18px;
}
.list-al {
    display: flex;
    margin-right:15px;
    margin-top:5px;
    margin-bottom:5px;
}
.table-wrap {
    width: 100%;      
    box-sizing: border-box;
    border-radius: 8px;
    background: white;
    margin:10 px ;     
    margin-top: 10px ; 
    margin-bottom: 20px ; 
    display: flex;  
    line-height: 32px;
    .blue {
        margin-left: unset;        
    }
    .table-mon {
        color: #4877E8;
        margin-left: 5px;
    }
}
.moadl-foot {
    margin-top: 12px;
    border-top: 1px solid #e8eaec;
    padding-top: 8px ;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    Button {
        margin: 0 10px;
        width: 80px;
    }
}
.moadl-footx {
    margin-top: 12px;
    padding-top: 8px ;
    border-top: 1px solid #e8eaec;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    Button {
        margin: 0 10px;
        width: 80px;
    }
}
.see-xq {
    cursor: pointer;
    text-decoration:underline;
}
.flex-s {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 28%;
}
::v-deep .ivu-modal-header p  {
    font-weight: 900
}
::v-deep .ivu-modal-header-inner {
    font-weight: 900
}
::v-deep .bottom-swip img{
    // width: 75%;
    // height: 75%;
    max-width: 100%;
    max-height: 100%;
    padding:0px 16px
    // margin-left: 10%;
    
}
.bottom-swip{
    width: 100%;
    margin-top: 15px;
    overflow: hidden;
    margin-bottom: 15px;
}
.mod-pay-succ{
    text-align: center;
    .titlesuc {
        margin-top: 50px;  
        font-size: 25px;
    }
    .over-list {
        text-align: start;
        margin: 20px 0px;
        font-size: 15px; 
        color: #000000;
        margin-left: 190px ;
        span {
            color: #666666;
        }
    }
    img {
        width: 80px;
        height: 80px;
        margin-top: 50px;
        margin-bottom: 10px;
    }
}
.mod-pay-cont{
    text-align: center;
    .pay-title{
        margin-top: 15px;
        display: flex;
        align-items: center;
        justify-content: center;        
    }
    .pay-explain{
        padding: 40px;
        font-family: PingFang SC;
    }
    .pay-title1 span{
        color: #545F78;
        font-size: 20px;
        font-family: PingFang SC;
    }
    .pay-ma {        
        text-align: center;
        width: 202px;
        height: 202px;
        border: 1px solid #4877E8;
        margin: 50px  175px;
        position: relative;
        img {
            width: 200px;
            height: 200px;
            margin: 0px 0px;
        }
    }
    .pay-num {
        width: 100%;
        height: 40px;
        margin-top:40px ;
        text-align: center;
        color: #4877e8;
    }
    .pay-num .line{
        position: relative;
        display: inline-block;
        padding: 0 30px;
    }    
    .pay-num .line::after{
        content:'';
        width:180px;
        position:absolute;
        top:20px;
        right:-160px;
        border-bottom:1px solid #4877E8;
    }
    .pay-num .line::before{
        content:'';
        width:180px;
        position:absolute;
        top:20px;
        left:-160px;
        border-bottom:1px solid #4877E8;
    }
    span{
        color: #545F78;
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: bold;
    }
    img {
        width: 40px;
        height: 40px;
    }
    .pay-pay{
        display: flex;
        align-items: center;
        justify-content: center;
        .pay-items{
            padding: 50px 0;
            width: 50%;
            text-align: center;
            img {
            cursor: pointer;
            width: 90px;
            height: 90px;
            }
        }
    }
}
 ::v-deep.canshowcont {
    .ivu-modal-close{
        display: none;
    }
}
</style>

<style>
.demo-spin-icon-load{
    animation: ani-demo-spin 3s linear infinite;
}
@keyframes ani-demo-spin {
    from { transform: rotate(0deg);}
    50%  { transform: rotate(180deg);}
    to   { transform: rotate(360deg);}
}
.demo-spin-col{
  top:80px;
  left:80px;
  position: relative;
  /* // border: 1px solid #eee; */
}
</style>